.page-not-found-image{
    width:30vw;
}

.page-not-found-h1{
    color:rgb(80, 80, 80);
    text-align: center;
}
@media only screen and (max-width: 1680px) {
    .page-not-found-image{
        width:40vw;
    }
}

@media only screen and (max-width: 1280px) {
    .page-not-found-image{
        width:50vw;
    }
}

@media only screen and (max-width: 850px) {
    .page-not-found-image{
        width:70vw;
    }
}

@media only screen and (max-width: 600px) {
    .page-not-found-image{
        width:90vw;
    }
}