.card-meta-data{
    p{
        margin-bottom:0px;
    }
    .time{
        font-size:12px;
        position: relative;
        margin-top:-15px;
    }
    .location{

    }
}