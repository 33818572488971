@import '../../../core/GlobalVariables/colors.scss';
.site-layout {
    min-height:100vh;

    .site-layout-header {
        background: $base-color-red-two;
        color:white;

        .menu-color-red{
            background: $base-color-red-two;

            .ant-menu-item-selected{
                background-color:#c74333!important;

            }

                .ant-menu-item:hover{
                    background-color:#c74333!important;
                }

        }

    }
    .site-layout-background {
        background: #fff;
    }

    .trigger {
        font-size: 18px;
        line-height: 64px;
        padding: 0 24px;
        cursor: pointer;
        transition: color 0.3s;
    }
}

    .ant-layout-sider-children{
        .logo-wrapper{
            background-color:$base-color-red;
            padding:16px;
        }
        .logo{
            
            height: 32px;
            overflow: hidden;
            h2{
              color:white;  
            }
        }
    }

    .ant-layout{
        .ant-layout-sider {
            background-color:$base-color-blue;
        }

        .ant-menu.ant-menu-sub.ant-menu-inline{
            background-color:$base-color-blue-three;
            .ant-menu-item-selected{
                background-color:$base-color-blue!important;
            }
        }
        .ant-menu-dark{
            background-color:$base-color-blue;

            .ant-menu-item-selected{
                background-color:$base-color-blue-two!important;
            }
        }
    }

.site-layout-page-title{
    font-size:24px;
    
    padding:15px 15px 0px 15px;
    margin-bottom:-15px;
}